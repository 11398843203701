import { ReactNode, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { FormattedMessage } from 'react-intl';
import useAppContext from 'hooks/useAppContext';
import MainCard from 'components/cards/MainCard';
import Transitions from 'components/extended/Transitions';
import { IconLogout, IconSettings } from '@tabler/icons-react';
import useConfig from 'hooks/useConfig';
import { ThemeMode } from 'types/theme';
import { Card, CardContent, Grid2 as Grid, SelectChangeEvent, Switch, Tooltip } from '@mui/material';
import { NotificationType } from 'entities/enums';
import { Info } from '@mui/icons-material';
import { AppConfig } from 'entities/base/AppConfig';
import useLocalStorage from 'hooks/useLocalStorage';
import { useCookies } from 'react-cookie';
import useMaps from 'hooks/useMaps';
import SystemSelect from 'components/shared/SystemSelect';

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { mode, borderRadius } = useConfig();
  const { contact, context, logout } = useAppContext();

  const { autoNavigate, setAutoNavigate } = useMaps();

  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [notification, setNotification] = useState<Date>();
  const [open, setOpen] = useState(false);

  const [cookies, setCookie, removeCookie] = useCookies(['bs-alarms'], { doNotUpdate: true });

  /**
   * anchorRef is used on different components and specifying one type leads to other components throwing an error
   * */
  const anchorRef = useRef<any>(null);

  // const getClientByBuilding = () => user?.contact?.clients;
  // const getSiteByBuilding = () => user?.contact?.clients;

  const handleLogout = async () => {
    try {
      await logout();
    } catch (err) {
      console.error(err);
    }
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<HTMLDivElement> | MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleAutoMapNavChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAutoNavigate(event.currentTarget.checked);
  };

  const handleSilenceAlarmChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const silence = event.currentTarget.checked;
    if (silence) {
      const silenceHours = 4;
      const startTime = new Date();
      startTime.setHours(startTime.getHours() + silenceHours);
      setNotification(startTime);
      setCookie('bs-alarms', startTime, {
        maxAge: 3600 * silenceHours, // 4 hours experation
      });
    } else {
      removeCookie('bs-alarms');
      setNotification(undefined);
    }
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    // console.log('ALARMS', cookies['bs-alarms']);
    setNotification(cookies['bs-alarms']);
  }, []);

  return (
    <>
      <Chip
        sx={{
          ml: 2,
          height: '48px',
          alignItems: 'center',
          borderRadius: '27px',
          transition: 'all .2s ease-in-out',
          borderColor: mode === ThemeMode.DARK ? 'dark.main' : 'primary.light',
          bgcolor: mode === ThemeMode.DARK ? 'dark.main' : 'primary.light',
          '&[aria-controls="menu-list-grow"], &:hover': {
            borderColor: 'primary.main',
            bgcolor: `${theme.palette.primary.main} !important`,
            color: 'primary.light',
            '& svg': {
              stroke: theme.palette.primary.light,
            },
          },
          '& .MuiChip-label': {
            lineHeight: 0,
          },
        }}
        icon={
          <Avatar
            alt='user-images'
            sx={{
              ...theme.typography.mediumAvatar,
              margin: '8px 0 8px 8px !important',
              cursor: 'pointer',
            }}
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup='true'
            color='inherit'
          >
            {/* {state.auth.user?.userName?.substring(0, 1).toUpperCase()} */}
          </Avatar>
        }
        label={<IconSettings stroke={1.5} size='24px' />}
        variant='outlined'
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup='true'
        onClick={handleToggle}
        color='primary'
        aria-label='user-account'
      />

      <Popper
        placement='bottom'
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 14],
            },
          },
        ]}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleClose}>
            <Transitions in={open} {...TransitionProps}>
              <Paper>
                {open && (
                  <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                    <Box sx={{ p: 2, pb: 0 }}>
                      <Stack>
                        <Stack direction='row' spacing={0.5} alignItems='center'>
                          <Typography variant='h4'>Good Day,</Typography>
                          <Typography component='span' variant='h4' sx={{ fontWeight: 400 }}>
                            {contact?.name}
                          </Typography>
                        </Stack>
                        {contact?.title && <Typography variant='subtitle2'>{contact?.title}</Typography>}
                        <Typography variant='subtitle2'>Role: {contact?.role}</Typography>
                      </Stack>
                    </Box>
                    {/* <PerfectScrollbar style={{ height: '100%', maxHeight: 'calc(100vh - 250px)', overflowX: 'hidden' }}> */}
                    <Box sx={{ p: 2, pt: 0 }}>
                      <List
                        component='nav'
                        sx={{
                          width: '100%',
                          maxWidth: 350,
                          minWidth: 300,
                          borderRadius: `${borderRadius}px`,
                          '& .MuiListItemButton-root': { mt: 0.5 },
                        }}
                      >
                        <Divider sx={{ marginTop: 1, marginBottom: 2 }} />
                        <SystemSelect />
                        <Divider sx={{ marginTop: 1 }} />
                        <Card sx={{ bgcolor: mode === ThemeMode.DARK ? 'dark.800' : 'primary.light', my: 2 }}>
                          <CardContent>
                            <Grid container spacing={3} direction='column'>
                              <Grid>
                                <Grid container alignItems='center' justifyContent='space-between'>
                                  <Grid>
                                    <Typography variant='subtitle1' display={'flex'} alignItems={'center'} gap={1}>
                                      {autoNavigate ? 'Disable' : 'Enable'} map auto navigation
                                      <Tooltip title={`When enabled the map won't navigate to the last signal raised`}>
                                        <Info fontSize='small' />
                                      </Tooltip>
                                    </Typography>
                                  </Grid>
                                  <Grid>
                                    <Switch
                                      color='primary'
                                      title={autoNavigate ? 'Enabled' : 'Disabled'}
                                      checked={autoNavigate}
                                      onChange={handleAutoMapNavChange}
                                      name='autoNav'
                                      size='small'
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid>
                                <Grid container alignItems='center' justifyContent='space-between'>
                                  <Grid>
                                    <Typography variant='subtitle1'>
                                      {notification !== undefined ? `Alarm silenced till ${new Date(notification).toLocaleTimeString()}` : 'Silence alarm for 4 hours'}
                                    </Typography>
                                  </Grid>
                                  <Grid>
                                    <Switch checked={notification !== undefined} onChange={handleSilenceAlarmChange} name='sdm' size='small' />
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                        <Divider />
                        <ListItemButton
                          disabled
                          sx={{ borderRadius: `${borderRadius}px`, mb: 1 }}
                          selected={selectedIndex === 0}
                          onClick={(event: React.MouseEvent<HTMLDivElement>) => navigate('/profile')}
                        >
                          <ListItemIcon>
                            <IconSettings stroke={1.5} size='20px' />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography variant='body2'>
                                <FormattedMessage id='account-settings' />
                              </Typography>
                            }
                          />
                        </ListItemButton>
                        <Divider />
                        <ListItemButton sx={{ borderRadius: `${borderRadius}px` }} selected={selectedIndex === 4} onClick={handleLogout}>
                          <ListItemIcon>
                            <IconLogout stroke={1.5} size='20px' />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography variant='body2'>
                                <FormattedMessage id='logout' />
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </List>
                    </Box>
                    {/* </PerfectScrollbar> */}
                  </MainCard>
                )}
              </Paper>
            </Transitions>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  );
};

export default ProfileSection;
