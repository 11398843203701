import { CircularProgress, IconButton, Stack } from '@mui/material';
import { InspectionResult } from 'entities';
import { InventoryOutlined } from '@mui/icons-material';
import { useState } from 'react';
import { InspectionResultState } from 'entities/enums';
import { green, red, yellow } from '@mui/material/colors';
import { DeviceVM } from 'entities/viewModels';

type InspectionDeviceActionProps = {
  item: DeviceVM;
  onClick: (item: DeviceVM) => Promise<void>;
};

export const InspectionDeviceAction = ({ item, onClick }: InspectionDeviceActionProps) => {
  const [disabled, setDisabled] = useState(false);

  const getFillColor = () => '#d9534';
  // item.state === InspectionResultState.Pending
  //   ? '#d9534'
  //   : item.state === InspectionResultState.Partially
  //     ? '#ffc0cb'
  //     : item.state === InspectionResultState.Failed
  //       ? '#ff0000'
  //      : '#6cc067';

  if (item) {
    return (
      <Stack direction='row' spacing={1} alignItems='center' height={'100%'}>
        <IconButton onClick={(_) => onClick(item)}>
          <InventoryOutlined sx={{ width: 20, height: 20, fill: getFillColor() }} />
        </IconButton>
      </Stack>
    );
  }

  const handlePassed = () => {
    setDisabled(true);
  };

  const handleFail = () => {
    setDisabled(true);
  };

  return disabled ? (
    <Stack direction='row' spacing={1} alignItems='center' height={'100%'}>
      <CircularProgress size={20} />
    </Stack>
  ) : (
    <Stack direction='row' spacing={1} alignItems='center' height={'100%'}>
      <InventoryOutlined sx={{ width: 20, height: 20, fill: true ? '#6cc067' : '#d9534' }} />
    </Stack>
  );
};
