import { useEffect, useRef, useState } from 'react';
import useSignals from 'hooks/useSignals';
import { FeatureGroup, useMap } from 'react-leaflet';
import logger from 'utils/logger';
import { MapMarker, MarkerType } from './MapMarker';
import { InspectionResult, Map, MapItem } from 'entities';
import { MapType } from '../MapContainer';
import useInspection from 'hooks/useInspection';
import { DeviceVM, MapItemVM, SignalStateVM } from 'entities/viewModels';
import useMaps from 'hooks/useMaps';
import useAppContext from 'hooks/useAppContext';
import useAxios from 'hooks/useAxios';
import { InspectionResultState } from 'entities/enums';
import Loader from 'components/shared/Loader';

type MapMarkerLayerProps = {
  map?: Map;
  mapType: MapType;
  zoom: number;
  layerItems?: MapItem[];
};

export const MapMarkerLayer = ({ map, mapType, zoom, layerItems }: MapMarkerLayerProps) => {
  const lmap = useMap();
  const { get } = useAxios();
  const { signalState } = useSignals();
  const { context } = useAppContext();
  const { inspectionId, type } = useInspection();

  const [loading, setLoading] = useState(false);

  const [states, setStates] = useState<SignalStateVM[]>();

  // needed for edit view
  const { mapItems, refreshMap } = useMaps();
  const markersRef = useRef(null); //<MarkerLayer>(null);

  const [iconSize, setIconSize] = useState(5);
  const [items, setItems] = useState<MapItemVM[]>();

  const [inspectionDevices, setInspectionDevices] = useState<DeviceVM[]>(); // place holder for all inspection for this map

  const getStates = () => {
    if (context?.systemId) {
      get<SignalStateVM[]>(`/api/signalstate/${context.systemId}/active`).then(setStates);
    } else setStates(undefined);
  };

  const getInspectionResults = () => {
    if (mapType === 'inspection' && context?.systemId) {
      setLoading(true);
      get<DeviceVM[]>(`/api/device/${context?.systemId}/${map?.id}/${type}${inspectionId ? `/${inspectionId}` : ''}`)
        .then(setInspectionDevices)
        .finally(() => setLoading(false));
    } else setInspectionDevices(undefined);
  };

  useEffect(() => {
    const size = (zoom <= 3 ? 1 : zoom <= 5 ? 2 : zoom <= 6 ? 3 : zoom <= 7 ? 5 : 10) * zoom;
    logger.log(`ZOOM:${zoom} ICONSIZE: ${size}px`);
    setIconSize(size);
  }, [zoom, map]);

  useEffect(() => {
    if (map) {
      setLoading(true);
      setItems(undefined);
      refreshMap().finally(() => setLoading(false));
    }
  }, [map]);

  useEffect(() => {
    if (map && mapItems) setItems(mapItems);
  }, [mapItems]);

  useEffect(() => {
    if (mapType !== 'edit') return;
    if (layerItems && layerItems.length > 0)
      setItems(layerItems); //[...(items ?? []), ...layerItems]);
    // else if (!devicesLayerV1.includes(map?.id ?? 0)) get<MapItemVM[]>(`/api/mapitem/${context?.systemId}/${map?.id}/items/v1`).then(setItems);
    else setItems([]);
  }, [layerItems]);

  useEffect(() => {
    getStates();
  }, [signalState]);

  useEffect(() => {
    getInspectionResults();
  }, [map, type, inspectionId]);

  useEffect(() => {
    getStates();
  }, []);

  return (
    <FeatureGroup ref={markersRef}>
      {loading && <Loader />}
      {!loading &&
        items?.map((m) => (
          <MapMarker
            item={m}
            key={m.id}
            zoom={zoom}
            size={iconSize}
            mapType={mapType}
            result={inspectionDevices?.find((r) => r.id === m.deviceId)}
            signal={states?.find((r) => r.signalActivated?.deviceID === m.deviceId)}
          />
        ))}
    </FeatureGroup>
  );
};
