import { Entity } from 'entities/base/Entity';

export function addOrUpdateArray<T extends Entity>(array: T[], element: T, propertyName: string = 'id'): T[] {
  const i = array.findIndex((e) => Object(e)[propertyName] === Object(element)[propertyName]);
  if (i > -1) array[i] = element;
  else array.push(element);

  return array;
}

export function updateArray<T extends Entity>(array: T[], element: T): T[] {
  const i = array.findIndex((e) => e.id === element.id);
  if (i > -1) array[i] = element;
  return array;
}

export const distinctArray = (array?: any[], key: string = 'id') => (array ? [...new Map(array.map((item) => [item[key], item])).values()] : array);
