import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ListSubheader from '@mui/material/ListSubheader';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import useAppContext from 'hooks/useAppContext';
import { AppRoles } from 'entities/enums';
import { inRole } from 'utils/extensions';
import { distinctArray } from 'utils/array';

export default function SystemSelect() {
  const { contact, context, clients, setContext } = useAppContext();
  const [options, setOptions] = React.useState<{ value: number; label: string }[]>();

  const isMapViewer = inRole(contact?.role, [AppRoles.MapViewer]);

  React.useEffect(() => {
    const _options = contact?.systems?.map((s) => ({
      value: s.id,
      label: `${s.building?.site.clientID === 1 ? 'AA' : 'JB'} ${s.building?.site.code} ${isMapViewer ? `${s.building?.name}` : `${s.building?.shortName} - ${s.name}`}`,
    }));
    // TODO set client short name
    setOptions(distinctArray(_options, 'label')?.sort((a, b) => a.label.localeCompare(b.label)));
  }, []);

  return (
    <FormControl sx={{ minWidth: 120, width: '100%' }}>
      <InputLabel htmlFor='system-select'>{isMapViewer ? 'System' : 'Building'}</InputLabel>
      <Select
        fullWidth
        size='small'
        native
        value={context?.systemId ?? -1}
        id='system-select'
        onChange={(e) => setContext(parseInt(e.target.value.toString()))}
        label={isMapViewer ? 'System' : 'Building'}
      >
        {options?.map((o) => (
          <option key={o.value} value={o.value}>
            {o.label}
          </option>
        ))}
      </Select>
    </FormControl>
  );
  {
    /* <FormControl sx={{ m: 1, minWidth: 120 }}>
        <InputLabel htmlFor='grouped-select'>Grouping</InputLabel>
        <Select defaultValue='' id='grouped-select' label='Grouping'>
          <MenuItem value=''>
            <em>None</em>
          </MenuItem>
          <ListSubheader>Category 1</ListSubheader>
          <MenuItem value={1}>Option 1</MenuItem>
          <MenuItem value={2}>Option 2</MenuItem>
          <ListSubheader>Category 2</ListSubheader>
          <MenuItem value={3}>Option 3</MenuItem>
          <MenuItem value={4}>Option 4</MenuItem>
        </Select>
      </FormControl> */
  }
}
