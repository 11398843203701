import { ReactNode, useEffect, useState } from 'react';
import { Container, Drawer, IconButton, Box, Grid2 as Grid, Button, ButtonGroup } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import MainCard from 'components/cards/MainCard';
import { gridSpacing } from 'config';
import { InspectionsForm } from 'forms/InspectionsForm';
import TableChartIcon from '@mui/icons-material/TableChart';
import MapIcon from '@mui/icons-material/Map';
import useConfirm from 'hooks/useConfirm';
import { MapContainer } from 'components/maps/MapContainer';
import useInspection from 'hooks/useInspection';
import { InspectionState } from 'entities/enums/InspectionState';
import { MapSearch } from 'components/maps/components/MapSearch';
import { Toggler } from 'components/shared/Toggler';
import { MapSelect } from 'components/maps/components/MapSelect';
import { useWindowSize } from 'hooks/useWindowSize';
import useMaps from 'hooks/useMaps';
import useSignals from 'hooks/useSignals';
import useAppContext from 'hooks/useAppContext';
import useAxios from 'hooks/useAxios';
import logger from 'utils/logger';
import useNotification from 'hooks/useNotification';
import { InspectionResultsTable } from 'collections/InspectionResultsTable';
import InspectionsTable from 'collections/InspectionsTable';
import { ClientTitle } from 'components/shared/ClientTitle';
import { DeviceVM } from 'entities/viewModels';

export const InspectionsView = () => {
  const { get } = useAxios();
  const { width } = useWindowSize();
  const { confirm, ConfirmDialog } = useConfirm();

  const { signal } = useSignals();
  const { error, warning } = useNotification();
  const { barcode, context, setBarcode } = useAppContext();
  const { autoNavigate, moveToItem, getMapItemByDeviceId } = useMaps();
  const { type, device, reload, setInspectionDevice, setMarkerType } = useInspection();

  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState<Symbol>();

  const [isMobile, setIsMobile] = useState(false);

  const [name, setName] = useState('');

  const [showDetails, setShowDetails] = useState(false);

  // const [show, setShow] = useState<MarkerType>('Uninspected');

  const [component, setComponent] = useState('table');

  const [stateLoader, setStateLoader] = useState(false);
  const [completeLoader, setCompleteLoader] = useState(false);

  const renderViews = (): ReactNode => {
    return component === 'table' ? (
      <InspectionResultsTable refresh={refresh} loading={loading} type={type} onClick={handleClick} onLocationClick={showOnMap} />
    ) : (
      <MapContainer mapType='inspection' height={'65vh'} />
    );
  };

  const renderHeader = (): ReactNode => {
    return isMobile ? (
      <>
        <Grid container spacing={gridSpacing}>
          <Grid size={{ xs: 12 }}>{component === 'map' && <MapSelect />}</Grid>
          <Grid size={{ xs: 12 }}>{component === 'map' && <MapSearch />}</Grid>
          <Grid size={{ xs: 12 }} container spacing={gridSpacing}>
            <Grid size={{ xs: 9 }}>
              <ButtonGroup variant='outlined' sx={{ height: '100%' }}>
                <Button variant={type === 'Uninspected' ? 'contained' : 'outlined'} onClick={() => setMarkerType('Uninspected')}>
                  Pending
                </Button>
                <Button variant={type === 'Inspected' ? 'contained' : 'outlined'} onClick={() => setMarkerType('Inspected')}>
                  Completed
                </Button>
                <Button variant={type === 'All' ? 'contained' : 'outlined'} onClick={() => setMarkerType('All')}>
                  All
                </Button>
              </ButtonGroup>
            </Grid>
            <Grid size={{ xs: 3 }} justifyContent={'flex-end'} flexGrow={1} display={'flex'} gap={1}>
              <Toggler
                id='inspection-view'
                value={component}
                values={[
                  { name: 'table', icon: <TableChartIcon /> },
                  { name: 'map', icon: <MapIcon /> },
                ]}
                onChange={setComponent}
              />
            </Grid>
          </Grid>
          <Grid size={{ xs: 12 }}>{renderViews()}</Grid>
        </Grid>
      </>
    ) : (
      <>
        <Grid container spacing={gridSpacing} mb={2}>
          <Grid size={{ xs: 4 }} mt={2}>
            <ButtonGroup variant='outlined'>
              <Button variant={type === 'Uninspected' ? 'contained' : 'outlined'} onClick={() => setMarkerType('Uninspected')}>
                Pending
              </Button>
              <Button variant={type === 'Inspected' ? 'contained' : 'outlined'} onClick={() => setMarkerType('Inspected')}>
                Completed
              </Button>
              <Button variant={type === 'All' ? 'contained' : 'outlined'} onClick={() => setMarkerType('All')}>
                All
              </Button>
            </ButtonGroup>
          </Grid>
          <Grid size={{ xs: 8 }} mt={2} justifyContent={'flex-end'} flexGrow={1} display={'flex'} gap={1}>
            {component === 'map' && (
              <>
                <MapSearch width={250} />
                <MapSelect width={250} />
              </>
            )}
            <Toggler
              id='inspection-view'
              value={component}
              values={[
                { name: 'table', icon: <TableChartIcon /> },
                { name: 'map', icon: <MapIcon /> },
              ]}
              onChange={setComponent}
            />
          </Grid>
          <Grid size={{ xs: 12 }}>{renderViews()}</Grid>
        </Grid>
      </>
    );
  };

  const handleClick = async (item: DeviceVM) => {
    await setInspectionDevice(item);
  };

  const showOnMap = (deviceId?: number) => {
    if (deviceId)
      getMapItemByDeviceId(deviceId).then((item) => {
        if (item) {
          setComponent('map');
          moveToItem(item);
        }
      });
  };

  useEffect(() => {
    if (width) setIsMobile(width < 450);
  }, [width]);

  // useEffect(() => {
  //   if (inspection) {
  //     setName(inspection.name);
  //   }
  // }, [inspection]);

  useEffect(() => {
    if (autoNavigate) showOnMap(signal?.deviceID);
  }, [signal]);

  useEffect(() => {
    setRefresh(Symbol());
    // getInspection(id);
  }, [reload]);

  useEffect(() => {
    if (barcode && context?.systemId) {
      get<DeviceVM>(`/api/device/${context?.systemId}/barcode/${barcode}`)
        .then((item) => {
          if (item) setInspectionDevice(item);
          else error(`No inspection item found for '${barcode}'`);
        })
        .catch((e) => {
          logger.logError(`FAILED '/api/device/${context?.systemId}/barcode/${barcode}' with:`, e);
          error(`Failed to fetch ${barcode}`);
        })
        .finally(() => {
          setBarcode(undefined);
        });
    }
  }, [barcode]);

  // if (loading) return <Loader />;

  return (
    <>
      <ConfirmDialog />
      {isMobile ? (
        <Grid container spacing={2}>
          <Grid size={{ xs: 12 }}>
            <ClientTitle prefix='Inspections for' showBuilding showSystem />
          </Grid>
          <Grid size={{ xs: 12 }}>{renderHeader()}</Grid>
          <Grid size={{ xs: 12 }}>{<InspectionsTable hideHeader={true} />}</Grid>
        </Grid>
      ) : (
        <MainCard title={<ClientTitle prefix='Inspections for' showBuildingShortName showSystem />} contentSX={{ display: 'flex', flexDirection: 'column' }}>
          <Grid container spacing={2}>
            <Grid size={{ xs: 9 }}>{renderHeader()}</Grid>
            <Grid size={{ xs: 3 }}>{<InspectionsTable hideHeader={true} />}</Grid>
          </Grid>
        </MainCard>
      )}
      <Drawer anchor={'right'} open={device?.inspectionResults !== undefined} onClose={() => setInspectionDevice(undefined)}>
        <Box
          component='div'
          sx={{
            padding: 2,
            minWidth: 350,
            maxWidth: 450,
            overflow: 'hidden',
          }}
        >
          <Container sx={{ display: 'flex', justifyContent: 'end', maxHeight: '80%' }}>
            <IconButton onClick={() => setInspectionDevice(undefined)}>
              <CancelIcon />
            </IconButton>
          </Container>
          <InspectionsForm data={device} />
        </Box>
      </Drawer>
    </>
  );
};
