import { ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Device, Issue, IssueNote, IssueState } from 'entities';
import { FieldAttributes, FieldType } from 'components/fields';
import { BaseForm } from './BaseForm';
import useAppContext from 'hooks/useAppContext';
import { Avatar, Box, Button, Divider, Grid2 as Grid, List, ListItem, ListItemAvatar, ListItemText, TextField, Typography } from '@mui/material';
import React from 'react';
import { Add } from '@mui/icons-material';
import useAxios from 'hooks/useAxios';
import { IssueType } from 'entities/enums';

const InitialState: Issue = {
  id: 0,
  name: '',
  closed: false,
};

type IssueFormProps = {
  entity?: Issue;
  disabled?: boolean;
  onCancel: VoidFunction;
  onSubmit: SubmitHandler<Issue>;
};

export const IssueForm = ({ disabled, entity, onCancel, onSubmit }: IssueFormProps) => {
  const { get, post } = useAxios();
  const { context, contact } = useAppContext();
  const methods = useForm<Issue>({
    defaultValues: useMemo(() => {
      return entity ?? InitialState;
    }, [entity]),
  });

  const [note, setNote] = useState('');
  const [showNote, setShowNote] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  const [issueStates, setIssueStates] = useState<IssueState[]>([]);

  const saveNote = () => {
    const issueNote: IssueNote = {
      name: note,
      id: 0,
    };

    if (contact && entity) {
      issueNote.issueId = entity.id;
      issueNote.contactId = contact.id;
      issueNote.contactName = contact?.name;
      issueNote.date = new Date().toISOString();

      entity.notes = [issueNote, ...(entity?.notes ?? [])];
      post<IssueNote>(`/api/issue/note`, issueNote);
      setNote('');
    }
    setShowNote(false);
  };

  const formSchema: FieldAttributes[] = [
    {
      label: 'Title',
      name: 'name',
      type: FieldType.TEXT,
      control: methods.control,
      span: entity ? 12 : 6,
      disabled: entity != undefined,
    },
    // {
    //   label: 'Type',
    //   name: 'type',
    //   type: FieldType.SELECT,
    //   control: methods.control,
    //   options: Object.keys(IssueType)
    //     .filter((t) => isNaN(parseInt(t)))
    //     .map((t, i) => {
    //       return { label: t, value: i };
    //     }),
    //   span: entity ? 12 : 3,
    // },
    // {
    //   label: 'State',
    //   name: 'state',
    //   type: FieldType.SOLOSELECT,
    //   data: issueStates,
    //   control: methods.control,
    //   span: entity ? 12 : 3,
    // },
    // {
    //   label: 'Description',
    //   name: 'description',
    //   type: FieldType.TEXT,
    //   multiline: true,
    //   control: methods.control,
    //   span: 12,
    //   disabled: entity != undefined,
    // },
    // {
    //   label: 'Device',
    //   name: 'deviceId',
    //   type: FieldType.LOOKUP,
    //   dataUrl: `/api/device/query/${context?.systemId}`,
    //   control: methods.control,
    //   span: entity ? 12 : 6,
    //   disabled: entity != undefined,
    // },
    // {
    //   label: 'Expected completion',
    //   name: 'expectedCompletion',
    //   type: FieldType.DATE,
    //   control: methods.control,
    //   span: entity ? 12 : 6,
    // },
  ];

  const renderNote = (note: IssueNote) => {
    return (
      <>
        <ListItem alignItems='flex-start'>
          <ListItemAvatar>
            <Avatar alt={note.contactName} />
          </ListItemAvatar>
          <ListItemText
            primary={`${note.contactName} on ${note.date ? new Date(note.date).toLocaleString() : ''}`}
            secondary={
              <React.Fragment>
                <Typography component='span' variant='body2' sx={{ mt: 2, color: 'text.primary', display: 'inline', whiteSpace: 'pre-wrap' }}>
                  {note.name}
                </Typography>
              </React.Fragment>
            }
          />
        </ListItem>
        <Divider variant='inset' component='li' />
      </>
    );
  };

  const renderIssueForm = (entity: Issue): ReactNode => {
    return (
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, md: 9 }}>
          <Box width={'100%'} display={'flex'} alignContent={'space-around'} alignItems={'center'} flexDirection={'row'} gap={5}>
            <Typography variant='h3'>Notes</Typography>
            {!showNote && (
              <Button size='small' variant='outlined' startIcon={<Add />} onClick={() => setShowNote(true)}>
                Add Note
              </Button>
            )}
          </Box>
          {showNote && (
            <Box display={'flex'} alignContent={'space-around'} alignItems={'left'} flexDirection={'column'} gap={2} sx={{ mt: 2 }}>
              <TextField multiline={true} fullWidth rows={5} onChange={(e) => setNote(e.target.value)} />
              <Button disabled={!isDirty} sx={{ width: 120 }} size='small' variant='outlined' onClick={saveNote}>
                Save
              </Button>
              <Divider variant='fullWidth' />
            </Box>
          )}
          <List sx={{ width: '100%', bgcolor: 'background.paper' }}>{entity.notes?.map(renderNote)}</List>
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <Box gap={1} display={'flex'} flexDirection={'column'} sx={{ mb: 2 }}>
            <Typography variant='h5' sx={{ fontWeight: 700 }}>
              {entity.device?.type?.name}
            </Typography>
            <Typography sx={{ fontSize: 'small' }}>Device: {entity.device?.name}</Typography>
            {entity.device?.address && <Typography sx={{ fontSize: 'small' }}>Address: {entity.device?.address}</Typography>}
            {(entity.device?.directions ?? entity.device?.room) && (
              <Typography sx={{ fontSize: 'small' }}>Directions: {entity.device?.directions ?? entity.device?.room}</Typography>
            )}
          </Box>
          <BaseForm methods={methods} readOnly={false} formSchema={formSchema} onCancel={onCancel} onSubmit={(d: Issue) => onSubmit(d)} />
        </Grid>
      </Grid>
    );
  };

  useEffect(() => {
    setIsDirty((note?.length ?? 0) > 0);
  }, [note]);

  useEffect(() => {
    get<IssueState[]>(`/api/issuestates`).then(setIssueStates);
  }, []);

  return entity ? renderIssueForm(entity) : <BaseForm methods={methods} readOnly={false} formSchema={formSchema} onCancel={onCancel} onSubmit={(d: Issue) => onSubmit(d)} />;
};
