import Loader from 'components/shared/Loader';
import { Map, MapItem } from 'entities';
import { createContext, ReactNode, useEffect, useReducer } from 'react';
import { ActionMap } from 'types/actionMap';
import { MarkerType } from 'components/maps/base/MapMarker';
import useAxios from 'hooks/useAxios';
import useAppContext from 'hooks/useAppContext';
import { MapItemVM } from 'entities/viewModels';
import { LatLngBounds } from 'leaflet';
import { AppConfig, InitialAppConfig } from 'entities/base/AppConfig';
import useLocalStorage from 'hooks/useLocalStorage';
import { useCookies } from 'react-cookie';

export type LayersMap = {
  [key: string]: boolean;
};

type MapProviderProps = {
  children: ReactNode;
};

interface MapContextState {
  markerType: MarkerType;
  searchQuery?: string;
  map?: Map;
  maps?: Map[];
  autoNavigate: boolean;

  mapItem?: MapItem;
  markerItem?: MapItem;
  mapItems?: MapItemVM[];

  layers: LayersMap;
  layerItems?: MapItemVM[];

  devicesLayerV1: number[];
}

interface MapContextType extends MapContextState {
  addMarker: (mapItem?: MapItem) => void;

  //setLayer: (layer?: IMapLayers) => void;
  setLayers: (layers: LayersMap) => void;
  setLayerItems: (items?: MapItemVM[]) => void;
  setMap: (mapId?: number) => void;
  setDevicesLayerV1: (mapIds: number[]) => void;
  setMapItems: (items?: MapItemVM[]) => void;
  setSearchQuery: (query?: string) => void;
  setAutoNavigate: (enabled: boolean) => Promise<void>;

  moveToItem: (item?: MapItem) => Promise<void>;
  getMapItemByAddress: (address?: string) => Promise<MapItemVM | undefined>;
  getMapItemByDeviceId: (id?: number) => Promise<MapItemVM | undefined>;
  showMarkerType: (markerType: MarkerType) => void;
  refreshMap: () => Promise<void>;
}

enum MapActionTypes {
  Search = 'SEARCH',
  Layer = 'LAYER',
  Layers = 'LAYERS',
  LayerItems = 'LAYERITEMS',
  AddMarker = 'ADD_MARKER',
  SetMap = 'SET_MAP',
  SetMaps = 'SET_MAPS',
  SetMapItems = 'SET_MAP_ITEMS',
  SetDevicesLayerV1 = 'SET_DEVICES_LAYER_V1',
  RefreshMap = 'REFRESH_MAP',
  MoveToItem = 'MOVE_TO_MAPITEM',
  OpenPopup = 'OPEN_POPUP',
  MarkerType = 'MARKER_TYPE',
  AutoNivigate = 'AUTO_NAVIGATE',
}

type MapPayload = {
  [MapActionTypes.Search]: string | undefined;

  //[MapActionTypes.Layer]: IMapLayers | undefined;
  [MapActionTypes.Layers]: LayersMap;
  [MapActionTypes.LayerItems]: MapItemVM[] | undefined;
  [MapActionTypes.AddMarker]: MapItem | undefined;
  [MapActionTypes.SetMap]: number | undefined;
  [MapActionTypes.SetMaps]: Map[] | undefined;
  [MapActionTypes.SetDevicesLayerV1]: number[];
  [MapActionTypes.SetMapItems]: MapItemVM[] | undefined;
  [MapActionTypes.MoveToItem]: MapItem | undefined;
  [MapActionTypes.OpenPopup]: number | undefined;
  [MapActionTypes.MarkerType]: MarkerType;
  [MapActionTypes.AutoNivigate]: boolean;
};

type MapActions = ActionMap<MapPayload>[keyof ActionMap<MapPayload>];

const InitialState: MapContextState = {
  markerType: 'All',
  map: undefined,
  maps: undefined,
  mapItem: undefined,
  markerItem: undefined,
  mapItems: undefined,
  layers: {},
  //layer: undefined,
  layerItems: undefined,
  devicesLayerV1: [], // [2, 3, 4, 5, 6, 8, 9, 10],
  autoNavigate: true,
};

const mapReducer = (state: MapContextState, action: MapActions): MapContextState => {
  switch (action.type) {
    case MapActionTypes.Search:
      return { ...state, searchQuery: action.payload };
    // case MapActionTypes.Layer:
    //   return { ...state, layer: action.payload };
    case MapActionTypes.Layers:
      return { ...state, layers: action.payload };
    case MapActionTypes.LayerItems:
      return { ...state, layerItems: action.payload };
    case MapActionTypes.SetMapItems:
      return { ...state, mapItems: action.payload };
    case MapActionTypes.AddMarker:
      return { ...state, markerItem: action.payload };
    case MapActionTypes.SetMap:
      return { ...state, map: state?.maps?.find((m) => m.id === action.payload) };
    case MapActionTypes.SetMaps:
      return { ...state, maps: action.payload, map: undefined };
    case MapActionTypes.SetDevicesLayerV1:
      return { ...state, devicesLayerV1: action.payload };
    case MapActionTypes.MoveToItem:
      const selected = action.payload;
      const map = selected ? state.maps?.find((m) => m.id === selected?.mapID) : state.map;
      return { ...state, map: map ?? state.map, mapItem: selected };
    case MapActionTypes.MarkerType:
      return { ...state, markerType: action.payload };
    case MapActionTypes.AutoNivigate:
      return { ...state, autoNavigate: action.payload };
    default:
      return { ...state };
  }
};

export const MapContext = createContext<MapContextType | null>(null);

export const MapProvider = ({ children }: MapProviderProps) => {
  const { get } = useAxios();
  const { context } = useAppContext();
  const [state, dispatch] = useReducer(mapReducer, InitialState);

  const [cookies, setCookie, removeCookie] = useCookies(['bs-alarm']);
  const [config, setConfig] = useLocalStorage<AppConfig>('bs-config', InitialAppConfig);

  const setSearchQuery = (query?: string) => dispatch({ type: MapActionTypes.Search, payload: query });

  //const setLayer = (layer?: IMapLayers) => dispatch({ type: MapActionTypes.Layer, payload: layer });

  const setLayers = (layers: LayersMap) => dispatch({ type: MapActionTypes.Layers, payload: layers });

  const setLayerItems = (items?: MapItemVM[]) => dispatch({ type: MapActionTypes.LayerItems, payload: items });

  const setMapItems = (items?: MapItemVM[]) => dispatch({ type: MapActionTypes.SetMapItems, payload: items });

  const setMap = (mapId?: number) => dispatch({ type: MapActionTypes.SetMap, payload: mapId });

  const setAutoNavigate = (enabled: boolean) => setConfig({ ...config, autoNavigate: enabled });

  const setDevicesLayerV1 = (mapIds: number[]) => dispatch({ type: MapActionTypes.SetDevicesLayerV1, payload: mapIds });

  const moveToItem = async (selected?: MapItem) => dispatch({ type: MapActionTypes.MoveToItem, payload: selected });

  const getMapItemByAddress = async (address?: string) => {
    if (!address) return undefined;
    return await get<MapItemVM>(`/api/mapitem/${context?.systemId}/address/${address}`);
  };

  const getMapItemByDeviceId = async (id?: number) => {
    if (!id) return undefined;
    return await get<MapItemVM>(`/api/mapitem/${context?.systemId}/${id}`);
  };

  const refreshMap = async () => {
    if (state.map?.id) {
      const mapItems = await get<MapItem[]>(`/api/mapitem/${context?.systemId}/${state.map?.id}/items`);
      dispatch({ type: MapActionTypes.SetMapItems, payload: mapItems });
    }
  };

  const addMarker = (mapItem?: MapItem) => dispatch({ type: MapActionTypes.AddMarker, payload: mapItem });

  const showMarkerType = (markerType: MarkerType) => dispatch({ type: MapActionTypes.MarkerType, payload: markerType });

  useEffect(() => dispatch({ type: MapActionTypes.SetMaps, payload: context?.maps }), [context, context?.maps]);

  useEffect(() => dispatch({ type: MapActionTypes.AutoNivigate, payload: config?.autoNavigate }), [config]);

  if (!state) {
    return <Loader />;
  }

  return (
    <MapContext.Provider
      value={{
        ...state,
        setSearchQuery,
        //setLayer,
        setLayerItems,
        setLayers,
        setMapItems,
        setMap,
        setAutoNavigate,
        setDevicesLayerV1,
        moveToItem,
        refreshMap,
        addMarker,
        showMarkerType,
        getMapItemByAddress,
        getMapItemByDeviceId,
      }}
    >
      {children}
    </MapContext.Provider>
  );
};
