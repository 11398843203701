import { Box, CircularProgress, IconButton, ListItem, ListItemText, Stack } from '@mui/material';
import { GridColDef, GridRenderCellParams, GridTreeNodeWithRender, useGridApiRef } from '@mui/x-data-grid';
import { DataGrid } from 'components/dataGrid/DataGrid';
import { renderProgress } from 'components/dataGrid/renderers/ProgressBar';
import { Inspection } from 'entities';
import useAppContext from 'hooks/useAppContext';
import { ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Edit, PlayArrow, Pause } from '@mui/icons-material';
import { InspectionState } from 'entities/enums/InspectionState';
import useInspection from 'hooks/useInspection';
import { Entity } from 'entities/base/Entity';

type InspectionsTableProps = {
  reload?: Symbol;
  limited?: boolean;
  disabled?: boolean;
  hideFooter?: boolean;
  hideHeader?: boolean;
  pageSizeOptions?: number[];
};

function RenderActionCell(params: GridRenderCellParams<Inspection, any, any, GridTreeNodeWithRender>): ReactNode {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const handleEdit = (id: number) => {
    navigate(`/inspections/${id}`);
  };

  useEffect(() => setLoading(false), [params.row.state]);

  return (
    <Stack direction='row' spacing={0.1} height={'100%'} alignItems='center'>
      <IconButton size='small' color='inherit' onClick={() => handleEdit(params.row.id)}>
        <Edit />
      </IconButton>
    </Stack>
  );
}

const RenderListItemTemplate = (entity: Entity, inspectionId: number | undefined, onChange: (inspectionId?: number) => void, disabled?: boolean) => {
  const item = entity as Inspection;
  // const { inspectionId, setInspectionId } = useInspection();

  // const style = () => (inspectionId === entity.id ? { border: '1px, inset, #ffffff', backgroundColor: '#ffffff14' } : {});

  return (
    <ListItem
      onClick={() => !disabled && onChange(entity.id === inspectionId ? undefined : entity.id)}
      sx={
        disabled
          ? {}
          : {
              ':hover': { backgroundColor: '#ffffff14', cursor: 'pointer' },
              ...(inspectionId === entity.id ? { border: '1px, inset, #ffffff', backgroundColor: '#ffffff14' } : {}),
            }
      }
    >
      <Box sx={{ mr: 2 }}>{renderProgress(item.progress ?? 0)}</Box>
      <ListItemText primary={item.name} secondary={`Due date: ${item.dueDate}`} />
    </ListItem>
  );
};

export default function InspectionsTable({ reload, hideFooter, hideHeader, disabled }: InspectionsTableProps) {
  const apiRef = useGridApiRef();
  const { context } = useAppContext();
  const { inspectionId, setInspectionId } = useInspection();

  const [dataUrl, setDataUrl] = useState<string>();

  const [refresh, setRefresh] = useState<Symbol>();

  const columns: GridColDef<Inspection>[] = [
    {
      field: 'progress',
      headerName: 'Progress',
      filterable: false,
      sortable: false,
      width: 110,
      disableColumnMenu: true,
      renderCell(params) {
        return renderProgress(params.row.progress ?? 0);
      },
    },
    {
      field: 'dueDate',
      headerName: 'Due date',
      filterable: false,
      sortable: false,
      width: 135,
      disableColumnMenu: true,
    },
    {
      field: 'name',
      headerName: 'Inspection',
      filterable: false,
      width: 400,
      disableColumnMenu: true,
    },
  ];

  useEffect(() => {
    setRefresh(Symbol());
  }, [inspectionId]);

  useEffect(() => {
    setDataUrl(`/api/inspection/query/${context?.systemId}`);
  }, [context?.systemId]);

  useEffect(() => {
    setDataUrl(`/api/inspection/query/${context?.systemId}`);
  }, []);

  return (
    <DataGrid
      api={apiRef}
      refresh={refresh}
      key={'inspectionsTable'}
      hideFooter={hideFooter}
      hideHeader={hideHeader}
      orderBy={'dueDate asc'}
      columnDefinitions={columns}
      dataUrl={dataUrl}
      listItemTemplate={(e) => RenderListItemTemplate(e, inspectionId, setInspectionId, disabled)}
    />
  );
}
