import { FormControl, FormLabel, Button, TextField, Box, ButtonGroup, SxProps, Theme } from '@mui/material';
import { useState } from 'react';
import { InspectionQuestion, InspectionQuestionResult, InspectionResult } from 'entities';
import { InspectionOutcome } from 'entities/enums/InspectionOutcome';
import useInspection from 'hooks/useInspection';
import { InspectionResultVM } from 'entities/viewModels';
import InputFileUploadField from 'components/fields/InputFileUploadField';
import { addOrUpdateArray } from 'utils/array';
import { LoadingButton } from '@mui/lab';
import { InspectionResultState } from 'entities/enums';
import { CollapsableContainer } from 'components/shared/CollapsableContainer';

type InspectionResultFormProps = {
  result?: InspectionResultVM;
  disabled?: boolean;
};

export const InspectionResultForm = ({ result, disabled }: InspectionResultFormProps) => {
  const [item, setItem] = useState(result!!);

  const questions = result?.inspection?.type?.questions ?? [];

  const [dirty, setDirty] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const { handleInspectionResultSubmit } = useInspection();

  const [helperText, setHelperText] = useState('Please submit a comment');

  const [expanded, setExpanded] = useState((item?.state ?? InspectionResultState.Pending) === InspectionResultState.Pending);

  const handleExpandClick = () => setExpanded(!expanded);

  const getResult = (id: number) => item?.results?.find((r) => r.inspectionQuestionID === id);

  const getFillColor = () =>
    item.state === InspectionResultState.Pending
      ? '#d9534'
      : item.state === InspectionResultState.Partially
        ? '#ffc0cb'
        : item.state === InspectionResultState.Failed
          ? '#ff0000'
          : '#6cc067';

  const setAnswer = (questionId: number, outcome: InspectionOutcome, comment: string = '') => {
    if (disabled) return;
    const result: InspectionQuestionResult = getResult(questionId) ?? { id: 0, inspectionQuestionID: questionId };

    const update: InspectionResult = {
      ...item!!,
      results: addOrUpdateArray(item?.results ?? [], { ...result, outcome: outcome, comment: comment, date: new Date().toISOString() }, 'inspectionQuestionID'),
    };
    setItem(update);
    setDirty(true);
  };

  const RenderQuestion = (question: InspectionQuestion, result?: InspectionQuestionResult) => {
    const handlOutcomeChange = (questionId: number, outcome: InspectionOutcome) => setAnswer(questionId, outcome);
    const handleCommentChange = (questionId: number, comment: string) => setAnswer(questionId, InspectionOutcome.FAIL, comment);

    const sx: SxProps<Theme> = disabled ? { ':hover': { cursor: 'default', backgroundColor: 'transparent' } } : {};

    return (
      <Box key={question.id} sx={{ mb: 1 }}>
        <Box display='flex' flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} gap={1} key={question.id}>
          <FormLabel id={'outcome-label'}>{question?.name}</FormLabel>
          <ButtonGroup size='small' aria-label='Small button group' sx={{ height: 20 }}>
            <Button
              sx={sx}
              key='yes'
              color={result?.outcome === InspectionOutcome.PASS ? 'success' : undefined}
              onClick={() => handlOutcomeChange(question.id, InspectionOutcome.PASS)}
            >
              Yes
            </Button>
            <Button
              sx={sx}
              key='no'
              color={result?.outcome === InspectionOutcome.FAIL ? 'error' : undefined}
              onClick={() => handlOutcomeChange(question.id, InspectionOutcome.FAIL)}
            >
              No
            </Button>
          </ButtonGroup>
        </Box>
        {result?.outcome === InspectionOutcome.FAIL && (
          <TextField
            multiline
            rows={3}
            fullWidth
            error={error}
            autoComplete='off'
            label='Comment'
            variant='outlined'
            margin={'normal'}
            value={result?.comment}
            disabled={disabled}
            required={result?.outcome === InspectionOutcome.FAIL}
            onChange={(e) => handleCommentChange(question.id, e.target.value)}
          />
        )}
        {/* <FormHelperText>{helperText}</FormHelperText> */}
      </Box>
    );
  };

  const handleAllPassed = () => {
    questions.forEach((q) => {
      setAnswer(q.id, InspectionOutcome.PASS);
    });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    setLoading(true);
    event.preventDefault();
    handleInspectionResultSubmit(item).finally(() => {
      setLoading(false);
      setExpanded(false);
    });
  };

  return (
    <form onSubmit={handleSubmit} key={`inspection_${result?.inspectionID}`}>
      <FormControl sx={{ m: 1, gap: '20px', width: '95%', border: `1px solid`, borderColor: getFillColor(), borderRadius: 0.8, p: 2 }} variant='standard'>
        <CollapsableContainer title={item.inspection?.name ?? 'UNKNOWN'} expanded={expanded} onClick={handleExpandClick}>
          {[...questions.filter((q) => q.deviceTypeID === null), ...questions.filter((q) => q.deviceTypeID === item?.device?.typeID)].map((q) =>
            RenderQuestion(q, getResult(q.id))
          )}
          <InputFileUploadField />
          <Box display='flex' flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <FormLabel id={'outcome-label'}>Does this pass all questions above?</FormLabel>
            <Button disabled={disabled} size='small' variant='outlined' key='yes' onClick={handleAllPassed}>
              Yes
            </Button>
          </Box>
          <LoadingButton loading={loading} disabled={!dirty} sx={{ mt: 1, mr: 1 }} type='submit' variant='contained'>
            Submit
          </LoadingButton>
        </CollapsableContainer>
      </FormControl>
    </form>
  );
};
