import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Avatar from '@mui/material/Avatar';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { InspectionResult, MapItem } from 'entities';
import { Box, Collapse, Divider, styled } from '@mui/material';
import { useState } from 'react';
import useInspection from 'hooks/useInspection';
import { MapLabel } from 'entities/MapLabel';
import { DeviceVM, SignalStateVM } from 'entities/viewModels';
import { ContentCopyOutlined } from '@mui/icons-material';
import useNotification from 'hooks/useNotification';
import { CollapsableContainer } from 'components/shared/CollapsableContainer';
import { InspectionDeviceAction } from 'components/actions/InspectionDeviceAction';

type MapItemCardProps = {
  item: MapItem;
  label?: MapLabel;
  device?: DeviceVM;
  bgColor: string;
  signal?: SignalStateVM;
};

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme }) => ({
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
  variants: [
    {
      props: ({ expand }) => !expand,
      style: {
        transform: 'rotate(0deg)',
      },
    },
    {
      props: ({ expand }) => !!expand,
      style: {
        transform: 'rotate(180deg)',
      },
    },
  ],
}));

export default function MapItemPopup({ item, label, device, bgColor, signal }: MapItemCardProps) {
  const { success } = useNotification();
  const [expanded, setExpanded] = useState(false);

  const { setInspectionDevice } = useInspection();

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleAddressCopy = () => {
    if (device?.address) {
      navigator.clipboard.writeText(device.address);
      success(`Copied ${device.address} to clipboard.`);
    }
  };

  if (!device && !label)
    return (
      <Card sx={{ maxHeight: 400, p: 0 }}>
        <CardHeader
          sx={{ p: 0, mb: 1 }}
          avatar={
            <Avatar sx={{ width: 50, height: 50 }} aria-label='recipe'>
              <img src={`/icons/notfound.svg`} style={{ width: 'auto', height: 45 }} alt={item.name} />
            </Avatar>
          }
          title={
            <Typography gutterBottom variant='h3' component='div'>
              {item.name}
            </Typography>
          }
          subheader={
            <Box display='flex' flexDirection='column'>
              {item.deviceId && (
                <Typography variant='h5' sx={{ color: 'text.secondary', margin: 0 }}>
                  {`DeviceID: ${item.deviceId}`}
                </Typography>
              )}
              <Typography variant='h5' sx={{ color: 'text.secondary', margin: 0 }}>
                LAT: {item.lat} LNG: {item.lng}
              </Typography>
            </Box>
          }
        />
        <CardContent sx={{ p: 0, m: 0 }}>
          <Typography component='div' sx={{ color: 'text.secondary', margin: 0, fontWeight: 900 }}>
            GENERATED BY Beacon Suite
          </Typography>
        </CardContent>
      </Card>
    );

  if (label) {
    return (
      <Card sx={{ maxHeight: 400, p: 0 }}>
        <CardHeader
          sx={{ p: 0, mb: 1 }}
          avatar={
            <Avatar sx={{ width: 50, height: 50 }} aria-label='recipe'>
              <img src={`/icons/labels/${label?.iconName}`} style={{ width: 'auto', height: 45 }} alt={label?.name} />
            </Avatar>
          }
          title={
            <Typography gutterBottom variant='h3' component='div'>
              {label?.name}
            </Typography>
          }
        />
        <CardContent sx={{ p: 0, m: 0 }}>
          <Typography component='div' sx={{ color: 'text.secondary', margin: 0, fontWeight: 900 }}>
            {label?.description}
          </Typography>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card sx={{ maxHeight: 400, p: 0 }}>
      <CardHeader
        sx={{ p: 0, mb: 1 }}
        avatar={
          <Avatar sx={{ width: 55, height: 55 }} aria-label='recipe'>
            <img
              src={device?.type?.iconName ? `/icons/devices/${device?.type?.iconName}` : '/icons/notfound.svg'}
              style={{ backgroundColor: bgColor, width: 55, height: 55 }}
              alt={device?.name}
            />
          </Avatar>
        }
        // action={
        //   <IconButton aria-label='settings'>
        //     <MoreVertIcon />
        //   </IconButton>
        // }
        title={
          <Typography gutterBottom variant='h3' component='div'>
            {device?.type?.name ?? device?.name}
          </Typography>
        }
        subheader={
          <>
            {device?.address && (
              <Box display='flex' gap={1} flexDirection='row' alignItems={'center'}>
                <Typography variant='h5' sx={{ color: 'text.secondary', margin: 0, display: 'flex', flexDirection: 'row', gap: 1 }}>
                  {`Address: `}
                </Typography>
                <Typography variant='h5' sx={{ color: 'text.secondary', margin: 0, fontWeight: 900 }}>
                  {device?.address ?? '-'}
                </Typography>
                <IconButton size='small' onClick={handleAddressCopy}>
                  <ContentCopyOutlined fontSize='small' sx={{ width: 10, height: 10 }} />
                </IconButton>
              </Box>
            )}
            {device?.barCode && <Typography variant='subtitle1' sx={{ color: 'text.secondary', margin: 0, fontStyle: 'italic' }}>{`Barcode: ${device?.barCode}`}</Typography>}
            {device?.hardwareType && (
              <Typography variant='subtitle1' sx={{ color: 'text.secondary', margin: 0, fontStyle: 'italic' }}>{`Hardware type: ${device?.hardwareType}`}</Typography>
            )}
          </>
        }
      />
      <CardContent sx={{ p: 0, m: 0 }}>
        <Typography component='div' sx={{ color: 'text.secondary', margin: 0, fontWeight: 900 }}>
          {device?.description ?? device?.name ?? ''}
        </Typography>
        {device?.zone && (
          <Typography variant='subtitle1' sx={{ color: 'text.secondary', margin: 0 }}>
            {device?.zone ? `Zone: ${device?.zone}` : ''}
          </Typography>
        )}
        {signal && (
          <Typography
            variant='subtitle1'
            sx={{ color: 'text.secondary', margin: 0, fontStyle: 'italic' }}
          >{`Status: ${signal?.signalActivated.type?.name ?? 'Normal'}`}</Typography>
        )}
        {(device?.inspectionResults?.length ?? 0) > 0 && (
          <Box sx={{ mt: 1 }}>
            <Divider />
            <Box sx={{ mt: 1 }} display={'flex'} flexDirection={'row'} alignItems={'center'} gap={1}>
              <Typography component='div' sx={{ color: 'text.secondary', margin: 0 }}>
                Inspection:
              </Typography>
              <InspectionDeviceAction item={device!!} onClick={async () => await setInspectionDevice(device)} />
            </Box>
          </Box>
        )}
      </CardContent>
      {((device?.directions?.trim().length ?? 0) > 0 || (device?.notes?.trim().length ?? 0) > 0) && (
        <CollapsableContainer expanded={expanded} title='Details' onClick={() => setExpanded(!expanded)}>
          <CardContent sx={{ p: 0, m: 0 }}>
            {device?.directions && (
              <Typography component='div' sx={{ color: 'text.secondary', margin: '0 0 1 0' }}>
                {`Directions: ${device.directions}`}
              </Typography>
            )}
            {device?.notes && (
              <Typography component='div' sx={{ color: 'text.secondary', margin: 0 }}>
                {`Notes: ${device.notes.replaceAll('_', ' ')}`}
              </Typography>
            )}
          </CardContent>
        </CollapsableContainer>
      )}
    </Card>
  );
}
