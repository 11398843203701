import { debounce, TextField } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';

type SearchFieldProps = {
  width?: number;
  onChange: (value: string) => void;
};

export const SearchField = ({ width, onChange }: SearchFieldProps) => {
  const _onChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => onChange(e.target.value);
  return <TextField fullWidth size='small' autoComplete='off' type='new-password' label='Search' onChange={debounce(_onChange, 1000)} />;
};
