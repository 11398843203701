import { AppRoles } from 'entities/enums';
import useAppContext from 'hooks/useAppContext';
import { inRole } from 'utils/extensions';

type SiteNameProps = {
  prefix?: string;
  suffix?: string;
  showClient?: boolean;
  showSite?: boolean;
  showBuilding?: boolean;
  showSiteCode?: boolean;
  showSystem?: boolean;
  showBuildingShortName?: boolean;
  showClientShortName?: boolean;
};

export const ClientTitle = ({ prefix, suffix, showClient, showSite, showBuilding, showSiteCode, showSystem, showBuildingShortName, showClientShortName }: SiteNameProps) => {
  const { context, contact } = useAppContext();

  if (inRole(contact?.role, [AppRoles.MapViewer])) return <>{`${prefix ?? ''} ${[context?.siteCode, context?.buildingName].join(' - ')} ${suffix ?? ''}`.trim()}</>;

  if (showClient && showBuilding && showSiteCode)
    return <>{`${prefix ?? ''} ${[context?.clientName, context?.siteName, context?.buildingName].join(' - ')} ${suffix ?? ''}`.trim()}</>;

  if (showBuildingShortName && showSiteCode && showSystem)
    return <>{`${prefix ?? ''} ${[context?.siteCode, context?.buildingShortName, context?.systemName].join(' - ')} ${suffix ?? ''}`.trim()}</>;

  if (showBuilding && showSiteCode && showSystem)
    return <>{`${prefix ?? ''} ${[context?.siteCode, context?.buildingName, context?.systemName].join(' - ')} ${suffix ?? ''}`.trim()}</>;

  if (showBuilding && showSystem) return <>{`${prefix ?? ''} ${[context?.buildingName, context?.systemName].join(' - ')} ${suffix ?? ''}`.trim()}</>;

  if (showBuilding && showSiteCode) return <>{`${prefix ?? ''} ${[context?.siteCode, context?.buildingName].join(' - ')} ${suffix ?? ''}`.trim()}</>;

  if (showBuildingShortName && showSystem) return <>{`${prefix ?? ''} ${[context?.buildingShortName, context?.systemName].join(' - ')} ${suffix ?? ''}`.trim()}</>;

  if (showClient) return <>{`${prefix ?? ''} ${context?.clientName ?? ''} ${suffix ?? ''}`.trim()}</>;
  if (showBuilding) return <>{`${prefix ?? ''} ${context?.buildingName ?? ''} ${suffix ?? ''}`.trim()}</>;
  if (showSite) return <>{`${prefix ?? ''} ${context?.siteName ?? ''} ${suffix ?? ''}`.trim()}</>;
  if (showSiteCode) return <>{`${prefix ?? ''} ${context?.siteCode ?? ''} ${suffix ?? ''}`.trim()}</>;
  if (showBuildingShortName) return <>{`${prefix ?? ''} ${context?.buildingShortName ?? ''} ${suffix ?? ''}`.trim()}</>;
  // TODO add client shortname
  if (showClientShortName) return <>{`${prefix ?? ''} ${''} ${suffix ?? ''}`.trim()}</>;
  if (showSystem) return <>{`${prefix ?? ''} ${context?.systemName ?? ''} ${suffix ?? ''}`.trim()}</>;

  if (prefix) return `${prefix} ${context?.buildingName}`;

  return <>{`${context?.siteCode} - ${showBuildingShortName ? context?.buildingShortName : context?.buildingName} - ${context?.systemName}`.trim()}</>;
};
