import { useEffect, useState } from 'react';
import MainCard from 'components/cards/MainCard';
import { ClientTitle } from 'components/shared/ClientTitle';
import { Box } from '@mui/material';
import { MapContainer } from 'components/maps/MapContainer';
import { useLoaderData } from 'react-router-dom';
import { MapSearch } from 'components/maps/components/MapSearch';
import { Toggler } from 'components/shared/Toggler';
import { MapSelect } from 'components/maps/components/MapSelect';
import useNotification from 'hooks/useNotification';
import useMaps from 'hooks/useMaps';
import useSignals from 'hooks/useSignals';
import { useWindowSize } from 'hooks/useWindowSize';
import useAppContext from 'hooks/useAppContext';
import { AppRoles } from 'entities/enums';
import { inRole } from 'utils/extensions';
import { SignalPlayer } from 'components/shared/SignalPlayer';
import logger from 'utils/logger';
import useAxios from 'hooks/useAxios';
import { Device } from 'entities';

export const loader = ({ params }: any) => params?.address ?? '';

const Maps = () => {
  const { get } = useAxios();
  const { signal } = useSignals();
  const { context, contact, setContext } = useAppContext();

  const params = useLoaderData() as string;

  const { width } = useWindowSize();
  const { warning } = useNotification();
  const [isLoading, setLoading] = useState(true);

  const { autoNavigate, markerType, moveToItem, getMapItemByAddress, getMapItemByDeviceId, showMarkerType } = useMaps();

  const isMapViewer = inRole(contact?.role, [AppRoles.MapViewer]);

  const [show, setShow] = useState(markerType === 'Alarm' ? 'Active' : 'All');

  useEffect(() => {
    if (show === 'Active') showMarkerType('Alarm');
    else showMarkerType('All');
  }, [show]);

  useEffect(() => {
    if (params) {
      getMapItemByAddress(params).then((item) => {
        if (!item?.device) return;
        if (item?.device?.systemID === context?.systemId) moveToItem(item);
        else {
          setContext(item?.device?.systemID).then(() => {
            if (item?.device?.address) getMapItemByAddress(item?.device?.address).then((item) => moveToItem(item));
          });
        }
      });
    }
  }, [params]);

  useEffect(() => {
    if (signal?.address && autoNavigate)
      getMapItemByDeviceId(signal.deviceID).then((item) => {
        if (item) moveToItem(item);
      });
  }, [signal]);

  // useEffect(() => {
  //   return () => {
  //     moveToItem(undefined);
  //   };
  // }, []);

  if (width!! <= 430) {
    return (
      <Box gap={2} flexDirection={'column'} display={'flex'}>
        <MapSelect />
        <MapSearch />
        <MapContainer mapType='alarm' />
      </Box>
    );
  }

  return (
    <MainCard title={<ClientTitle prefix={'Maps for'} showBuildingShortName showSystem />} secondary={<MapSearch />}>
      <Box display={'flex'} justifyContent={isMapViewer ? 'flex-end' : 'space-between'} mb={1} gap={1}>
        {!isMapViewer && <Toggler id='maps-state' values={[{ name: 'All' }, { name: 'Active' }]} value={show} onChange={setShow} disabled={context?.maps?.length === 0} />}
        {/* {!isMapViewer && width!! > 450 && <SignalPlayer disabled />} */}
        <MapSelect width={250} />
      </Box>
      <MapContainer mapType='alarm' height={'70vh'} />
    </MainCard>
  );
};

export default Maps;
