import { Autocomplete, Avatar, Box, TextField } from '@mui/material';
import { MapItem } from 'entities';
import useAppContext from 'hooks/useAppContext';
import useAxios from 'hooks/useAxios';
import { useEffect, useState } from 'react';
import { styled, lighten, darken } from '@mui/system';
import useMaps from 'hooks/useMaps';
import { MapItemVM } from 'entities/viewModels';

type MapSearchProps = {
  width?: number;
  nativeCSS?: React.CSSProperties;
};

export const MapSearch = ({ width, nativeCSS }: MapSearchProps) => {
  const { get } = useAxios();
  const { context } = useAppContext();
  const { moveToItem } = useMaps();
  const [loading, setLoading] = useState(true);

  const [mapNames, _] = useState<{ id: number; name: string }[]>(context?.maps?.map((m) => ({ id: m.id, name: m.name })) ?? []);

  const [value, setValue] = useState('');
  const [options, setOptions] = useState<MapItemVM[]>([]);
  const [selectedValue, setSelectedValue] = useState<MapItemVM>();
  const [loadingText, setLoadingText] = useState('Search for a device or location');

  const getMapName = (id?: number): string => mapNames.find((m) => m.id === id)?.name ?? id?.toString() ?? '';

  const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    zIndex: 1000,
    color: theme.palette.primary.main,
    backgroundColor: lighten(theme.palette.primary.light, 0.85),
    ...theme.applyStyles('dark', {
      backgroundColor: darken(theme.palette.primary.main, 0.8),
    }),
  }));

  const GroupItems = styled('ul')({
    padding: 0,
  });

  useEffect(() => {
    if (value && value.length >= 3) {
      setLoadingText('Searching...');
      get<MapItem[]>(`/api/mapitem/query/${context?.systemId}`, { search: value, pageSize: 100, page: 0 }).then((o) => {
        setOptions(o);
        if (o?.length === 0) setLoadingText('No results found');
      });
    } else {
      setOptions([]);
      setLoadingText('Search for a device or location');
    }
  }, [value]);

  useEffect(() => {
    if (selectedValue) moveToItem(selectedValue);
  }, [selectedValue]);

  // TODO implememt handler
  if (nativeCSS) return <input disabled={context?.maps?.length === 0} style={nativeCSS} placeholder='Search maps' />;

  return (
    <Autocomplete
      freeSolo
      blurOnSelect
      clearOnEscape
      size='small'
      fullWidth={!width}
      loading={loading}
      disabled={context?.maps?.length === 0}
      loadingText={loadingText}
      sx={{ width: width, minWidth: 250 }}
      getOptionKey={(i) => (typeof i === 'string' ? i : i.id)}
      getOptionLabel={(i) => (typeof i === 'string' ? i : (i.device?.name ?? i.device?.description ?? i.name))}
      onChange={(_, i) => typeof i !== 'string' && setSelectedValue(i ?? undefined)}
      renderInput={(params) => (
        <TextField
          autoComplete='off'
          {...params}
          type='new-password'
          label='Search maps'
          //variant='outlined'
          onChange={(e) => setValue(e.target.value)}
          // slotProps={{
          //   input: {
          //     ...params.InputProps,
          //     endAdornment: (
          //       <React.Fragment>
          //         {loading ? <CircularProgress color='inherit' size={20} /> : null}
          //         {params.InputProps.endAdornment}
          //       </React.Fragment>
          //     ),
          //   },
          // }}
        />
      )}
      options={options?.sort((a, b) => getMapName(a.mapID).localeCompare(getMapName(b.mapID)))} //(option) => option.mapID ?? 0)}
      renderOption={(props, option) => {
        const { key, ...optionProps } = props;
        return (
          <Box key={key} gap={1} component='li' sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...optionProps}>
            <Avatar sx={{ width: 24, height: 24, bgcolor: option.deviceId ? 'lightblue' : 'lightgreen' }}>{option.deviceId ? 'D' : 'L'}</Avatar>
            <>{option.device?.name ?? option.device?.description ?? option.name}</>
          </Box>
        );
      }}
      groupBy={(option) => getMapName(option.mapID)}
      filterOptions={(x) => x}
      renderGroup={(params) => (
        <li key={params.key}>
          <GroupHeader>{params.group}</GroupHeader>
          <GroupItems>{params.children}</GroupItems>
        </li>
      )}
    />
  );
};
