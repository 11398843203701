import { AccessTimeFilled, CheckCircle, HelpOutline } from '@mui/icons-material';
import { Box } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { IconCross } from '@tabler/icons-react';
import { DataGrid, DataGridActionProps } from 'components/dataGrid/DataGrid';
import { Beacon, BeaconPulse } from 'entities';
import { BeaconPulseState, SignalParseType } from 'entities/enums';
import { BeaconConfigVM } from 'entities/viewModels';
import useAppContext from 'hooks/useAppContext';
import useAxios from 'hooks/useAxios';
import { useEffect, useState } from 'react';

interface BeaconsTableActions extends DataGridActionProps<Beacon> {}

export default function BeaconsTable({ actions }: BeaconsTableActions) {
  const { get } = useAxios();
  const { context } = useAppContext();

  const [pulses, setPulses] = useState<BeaconPulse[]>();

  const renderIcons = (pulses?: BeaconPulse[]) => {
    const icons = [];
    for (let index = 0; index < 4; index++) {
      const element = pulses?.at(index);
      const date = element?.date ? `${new Date(element.date).toLocaleDateString()} at ${new Date(element.date).toLocaleTimeString()}` : '';
      icons.push(
        element?.state === BeaconPulseState.NoPulse ? (
          <AccessTimeFilled color='error' titleAccess={date} />
        ) : element?.state === BeaconPulseState.Ok ? (
          <CheckCircle color='success' titleAccess={date} />
        ) : (
          <HelpOutline />
        )
      );
    }

    return (
      <Box display={'flex'} alignItems={'center'} height={'100%'} gap={1}>
        {icons.map((i) => i)}
      </Box>
    );
  };

  const columns: GridColDef<Beacon>[] = [
    {
      field: 'pulses',
      headerName: 'Pulses',
      filterable: false,
      width: 150,
      renderCell(params) {
        return renderIcons(pulses?.filter((p) => params.row.name === p.name));
      },
    },
    {
      field: 'name',
      headerName: 'Name',
      filterable: false,
      width: 200,
    },
    {
      field: 'configs',
      headerName: 'System(s)',
      filterable: false,
      width: 500,
      renderCell(params) {
        var configs = params.row.configs as BeaconConfigVM[];
        console.log('CONFIGS', configs);
        return configs
          ?.map((c) => `${c.system?.building?.shortName} - ${c.system?.name} [SYSID: ${c.system?.id}, PARSER: ${c.parser !== undefined ? SignalParseType[c.parser] : ''}]`)
          .join('; ');
      },
    },
    {
      field: 'antiFloodEnabled',
      headerName: 'Anti Flood Signals',
      filterable: false,
      width: 300,
      renderCell(params) {
        return params.value ? `Enabled, ${params.row.antiFloodCount} signals within ${(params.row.antiFloodInterval ?? 0) / 1000} seconds` : 'Not enabled';
      },
    },
  ];

  const getPulses = () => get<BeaconPulse[]>(`/api/beacon/pulses/${context?.clientId}`).then(setPulses);

  useEffect(() => {
    getPulses();
  }, []);

  return (
    <DataGrid
      key={'beaconsTable'}
      dataUrl={`/api/beacon/query/${context?.clientId}`}
      hideHeader={false}
      orderBy={'name asc'}
      columnDefinitions={actions ? [...actions, ...columns] : columns}
    />
  );
}
