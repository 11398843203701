import React from 'react';
import Box from '@mui/material/Box';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { QrCode, QrCodeScannerOutlined } from '@mui/icons-material';
import { AppRoles } from 'entities/enums';
import { inRole } from 'utils/extensions';
import useAppContext from 'hooks/useAppContext';

type ScannerSectionProps = {
  handleClick: () => void;
};

const ScannerSection = ({ handleClick }: ScannerSectionProps) => {
  const { contact } = useAppContext();
  const [alignment, setAlignment] = React.useState<string | null>();

  const handleAlignment = (event: React.MouseEvent<HTMLElement>, newAlignment: string | null) => {
    setAlignment(newAlignment);
    handleClick();
  };

  if (!inRole(contact?.role, [AppRoles.Inspector, AppRoles.Admin, AppRoles.Manager])) return <></>;
  return (
    <Box sx={{ ml: 2 }}>
      <ToggleButtonGroup value={alignment} exclusive onChange={handleAlignment} aria-label='text alignment'>
        <ToggleButton value={'scanner'}>{alignment === 'scanner' ? <QrCodeScannerOutlined /> : <QrCode />}</ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
};

export default ScannerSection;
