import { GridColDef } from '@mui/x-data-grid';
import { DataGrid, DataGridActionProps } from 'components/dataGrid/DataGrid';
import { Issue } from 'entities';
import useAppContext from 'hooks/useAppContext';
import { useState, useEffect } from 'react';

interface EventItemsTableActions extends DataGridActionProps<Issue> {}

interface IssuesTableProps extends EventItemsTableActions {
  pageSizeOptions?: number[];
}

export default function IssuesTable({ actions, pageSizeOptions }: IssuesTableProps) {
  const { context } = useAppContext();
  const [dataUrl, setDataUrl] = useState<string>();
  const columns: GridColDef<Issue>[] = [
    {
      field: 'state',
      headerName: 'State',
      filterable: false,
      width: 100,
      renderCell(params) {
        return params?.row?.closed ? 'Closed' : params?.row?.state?.name;
      },
    },
    {
      field: 'name',
      headerName: 'Issue',
      filterable: false,
      width: 300,
    },
    {
      field: 'date',
      headerName: 'Create Date',
      filterable: false,
      width: 200,
      renderCell(params) {
        return <>{new Date(params.value).toLocaleDateString()}</>;
      },
    },
    {
      field: 'lastUpdate',
      headerName: 'Last Update',
      filterable: false,
      width: 200,
      renderCell(params) {
        return params.value ? <>{new Date(params.value).toLocaleDateString()}</> : '';
      },
    },
    {
      field: 'expectedCompletion',
      headerName: 'Expected Completion',
      filterable: false,
      width: 200,
      renderCell(params) {
        return params.value ? <>{new Date(params.value).toLocaleDateString()}</> : '';
      },
    },
  ];

  useEffect(() => {
    setDataUrl(`/api/issue/query`);
  }, []);

  return (
    <DataGrid
      id={'issuesTable'}
      dataUrl={dataUrl}
      hideHeader={true}
      orderBy={'date desc'}
      pageSizeOptions={pageSizeOptions}
      columnDefinitions={actions ? [...actions, ...columns] : columns}
    />
  );
}
