import { FormLabel, Divider, List, ListItem, ListItemText, ListItemIcon, Typography, Box } from '@mui/material';
import { Sensors } from '@mui/icons-material';
import { DeviceVM, SignalVM } from 'entities/viewModels';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { InspectionResultForm } from './InspectionResultForm';

type InspectionsFormProps = {
  signals?: SignalVM[];
  data?: DeviceVM;
  disabled?: boolean;
};

export const InspectionsForm = ({ data, signals, disabled }: InspectionsFormProps) => {
  return (
    <Box sx={{ m: 1, gap: '20px', width: '95%' }}>
      <Box gap={1} display={'flex'} flexDirection={'column'}>
        <Typography sx={{ fontWeight: 700 }}>{data?.name}</Typography>
        {data?.type?.name && <Typography sx={{ fontSize: 'small' }}>Type: {data?.type?.name}</Typography>}
        {data?.address && <Typography sx={{ fontSize: 'small' }}>Address: {data?.address}</Typography>}
        {data?.barCode && <Typography sx={{ fontSize: 'small' }}>Barcode: {data?.barCode}</Typography>}
        <Divider />
      </Box>

      <PerfectScrollbar
        aria-hidden='false'
        style={{
          overflowX: 'hidden',
          height: '70vh',
        }}
      >
        {data?.inspectionResults?.sort((a, b) => new Date(a.dueDate!!).getTime() - new Date(b.dueDate!!).getTime())?.map((i, k) => <InspectionResultForm result={i} key={k} />) ?? (
          <></>
        )}
      </PerfectScrollbar>

      {signals && (
        <>
          <Divider />
          <FormLabel sx={{ fontWeight: 700 }}>Signal(s) raised</FormLabel>
          <List dense={true}>
            {signals?.map((s) => (
              <ListItem key={s.id}>
                <ListItemIcon>
                  <Sensors />
                </ListItemIcon>
                <ListItemText primary={`${s.type?.name} ${s.address ? `[${s.address}]` : ''}`} secondary={new Date(s.date).toLocaleString()} />
              </ListItem>
            ))}
          </List>
        </>
      )}
    </Box>
  );
};
