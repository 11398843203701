import { LocationOn } from '@mui/icons-material';
import { Box, IconButton, Link, ListItem, Stack, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { InspectionDeviceAction } from 'components/actions/InspectionDeviceAction';
import { DataGrid } from 'components/dataGrid/DataGrid';
import { MarkerType } from 'components/maps/base/MapMarker';
import { DeviceVM } from 'entities/viewModels';
import { ReactNode, useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import useInspection from 'hooks/useInspection';
import useAppContext from 'hooks/useAppContext';
import { Entity } from 'entities/base/Entity';

type InspectionResultsTableProps = {
  type: MarkerType;
  refresh?: Symbol;
  loading: boolean;
  onClick: (item: DeviceVM) => Promise<void>;
  onLocationClick: (deviceId?: number) => void;
};

export const InspectionResultsTable = ({ type, refresh, loading, onClick, onLocationClick }: InspectionResultsTableProps) => {
  const { context } = useAppContext();
  const { inspectionId } = useInspection();
  const [dataUrl, setDataUrl] = useState<string>();

  const RenderListItemTemplate = (entity: Entity) => {
    const item = entity as DeviceVM;

    return (
      <ListItem sx={{ pl: 1 }}>
        <Box sx={{ mr: 1 }}>
          {RenderMapActionCell(item)}
          {RenderActionCell(item)}
        </Box>
        <Box display={'flex'} flexDirection={'column'} onClick={() => onClick(item)}>
          <Typography>{item.description && item.description.length > 0 ? item.description : item.name}</Typography>
          <Typography variant='caption'>{'Due date: ' + item.inspectionDueDate}</Typography>
          {item.address && <Typography variant='caption'>{'Adddress: ' + item.address}</Typography>}
          {!item.address && item.directions && <Typography variant='caption'>{'Directions: ' + item.directions}</Typography>}
        </Box>
      </ListItem>
    );
  };

  const RenderActionCell = (item: DeviceVM): ReactNode => {
    return <InspectionDeviceAction item={item} onClick={onClick} />;
  };

  const RenderMapActionCell = (item: DeviceVM): ReactNode => {
    // if (params.row.device?.address === null) return <></>;

    return (
      <Stack direction='row' height={'100%'} alignItems='center'>
        <IconButton size='small' title='Show on map' color='inherit' onClick={() => onLocationClick(item.id)}>
          <LocationOn />
        </IconButton>
      </Stack>
    );
  };

  const getInspectionTypeDataColumns = (): GridColDef<DeviceVM>[] => [];
  // !context?.hasSignals
  //   ? []
  //   : [
  //       {
  //         field: 'signals',
  //         headerName: 'Signals',
  //         width: 400,
  //         filterable: false,
  //         renderCell(params) {
  //           const signals =
  //             params.row.signals
  //               ?.map((s) => s.type?.name ?? '')
  //               .filter((t) => t.length > 0)
  //               .join(', ') ?? '';
  //           return <div title={signals}>{signals}</div>;
  //         },
  //       },
  //     ];

  const columns: GridColDef<DeviceVM>[] = [
    {
      field: 'actions',
      headerName: '',
      width: 80,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell(params) {
        return RenderMapActionCell(params.row);
      },
    },
    {
      field: 'result',
      headerName: 'Result',
      width: 90,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell(params) {
        return RenderActionCell(params.row);
      },
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 300,
      filterable: false,
      renderCell(params) {
        let title = params.value?.trim() ?? '';
        if (title.length === 0) title = params.row.name ?? '';
        return <div title={title}>{title}</div>;
      },
    },
    {
      field: 'inspectionDueDate',
      headerName: 'Due Date',
      width: 200,
      filterable: false,
      renderCell(params) {
        return params.value ? params.value : '';
      },
    },
    // {
    //   field: 'type',
    //   headerName: 'Type',
    //   width: 200,
    //   filterable: false,
    //   renderCell(params) {
    //     return <div title={params.row.type?.name ?? ''}>{params.row.type?.name ?? ''}</div>;
    //   },
    // },
    {
      field: 'address',
      headerName: 'Address',
      width: 150,
      filterable: false,
      renderCell(params) {
        return params.row ? (
          <Link component={RouterLink} to={`/devices/${params.row.id}`}>
            {params.row.address ?? ''}
          </Link>
        ) : (
          <></>
        );
      },
    },
    ...getInspectionTypeDataColumns(),
  ];

  useEffect(() => {
    setDataUrl(`/api/device/query/${context?.systemId}/${type}${inspectionId ? '/' + inspectionId : ''}`);
  }, [type, context, inspectionId]);

  return (
    <DataGrid
      id={'deviceInspectionsTable'}
      refresh={refresh}
      isLoading={loading}
      hideHeader={false}
      dataUrl={dataUrl}
      orderBy='date desc'
      columnDefinitions={columns}
      listItemTemplate={RenderListItemTemplate}
    />
  );
};
