import React, { useEffect, useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import useMaps from 'hooks/useMaps';
import useSignals from 'hooks/useSignals';
import { AlarmFab } from 'components/shared/AlarmFab';
import { getMomentum } from 'utils/dateHelper';
import useAppContext from 'hooks/useAppContext';
import { SignalStateVM } from 'entities/viewModels';
import { get } from 'http';
import useAxios from 'hooks/useAxios';

type MapSignalsProps = {
  width?: number;
  nativeCSS?: React.CSSProperties;
};

export const MapSignals = ({ width, nativeCSS }: MapSignalsProps) => {
  const { get } = useAxios();
  const { context } = useAppContext();
  const { getMapItemByAddress, moveToItem } = useMaps();
  const [selected, setSelected] = useState<number>();

  const [states, setStates] = useState<SignalStateVM[]>();

  const handleSelectChange = (value: string | number) => {
    const id = parseInt(value.toString());
    if (!isNaN(id)) {
      const address = states?.find((s) => s.id === id)?.signalActivated.address;
      if (address)
        getMapItemByAddress(address).then((item) => {
          if (item) {
            moveToItem(item);
          }
        });
    } else {
    }
  };

  useEffect(() => {
    if (context?.systemId) {
      get<SignalStateVM[]>(`/api/signalstate/${context.systemId}/active`).then(setStates);
    } else setStates(undefined);
  }, [context, context?.systemId]);

  if (nativeCSS)
    return (
      <select disabled={context?.maps?.length === 0 || (states?.length ?? 0) === 0} style={nativeCSS}>
        {states?.map((m) => (
          <option key={m.id} value={m.id}>
            {m.name} {getMomentum(new Date(m.date))}
          </option>
        )) ?? <option>No active signal(s)</option>}
      </select>
    );

  return (
    <FormControl disabled={!states} size='small' sx={width ? { width: width } : {}} fullWidth={!width}>
      <InputLabel id='signal-label'>Active Signal(s)</InputLabel>
      <Select labelId={'signal-label'} value={selected ?? ''} label='Active Signal(s)' onChange={(e) => handleSelectChange(e.target.value)}>
        {states?.map((m) => (
          <MenuItem key={m.id} value={m.id}>
            {getMomentum(new Date(m.date))} {m.name}
          </MenuItem>
        ))}
      </Select>
      <AlarmFab />
    </FormControl>
  );
};
